<template>
  <!-- Full size variant -->
  <div
    v-if="variant === 'full'"
    :class="['nav-item-full', selected ? 'selected' : '']"
    @click="$emit('click')"
  >
    <div class="icon-full">
      <component :is="icon" />
    </div>
    <span>{{ name }}</span>
  </div>

  <!-- Compacted size variant -->
  <div v-else>
    <div
      :id="name.toLowerCase()"
      :class="['nav-item-short', selected ? 'selected' : '']"
      @click="$emit('click')"
    >
      <div class="icon-short">
        <component :is="icon" />
      </div>
    </div>
    <Tooltip
      :target="name.toLowerCase()"
      :text="name"
    />
  </div>
</template>

<script>
  import Tooltip from './Tooltip.vue';
  export default {
    components: { Tooltip },
    props: {
      name: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      },
      icon: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  .icon-full {
    display: flex;
    align-items: center;

    svg {
      height: 14px;
      width: 14px;
    }
  }
  .icon-short {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .nav-item-full {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 8px 16px;
    gap: 8px;

    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    cursor: pointer;
    user-select: none;

    span {
      font-family: Raleway;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex: 1;
      color: #998f8a;
    }

    &:hover {
      background-color: #ffede2;
      span {
        color: #974900;
      }
    }

    &:active {
      background-color: #ffdbc4;
      color: #974900;
    }

    &.selected {
      background-color: #974900;
      svg {
        fill: #fff !important;
        stroke: #fff !important;
      }
      span {
        color: #fff;
      }
    }
  }

  .nav-item-short {
    padding: 6px 12px;
    border-radius: 5px;
    max-width: max-content;

    cursor: pointer;
    border: 1px solid transparent;

    min-width: 48px;
    height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;

    user-select: none;
    margin: 0 auto;

    &:hover {
      background: #ffede2;
    }

    &:active {
      background: #ffdbc4;
    }

    &.selected {
      background-color: #974900;
      svg {
        fill: #fff !important;
        stroke: #fff !important;
      }
    }
  }
</style>
