<template>
  <layout-horizontal>
    <router-view />
  </layout-horizontal>
</template>

<script>
  import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
  export default {
    components: {
      LayoutHorizontal
    },
    mounted() {
      document.querySelector('.app-content.content').classList.add('sidebar-close');
    }
  };
</script>
<style lang="scss">
  .app-content {
    padding: 20px 29px 0 !important;
  }

  @media (max-width: 768px) {
    .app-content {
      padding: 80px 29px 0 !important;
    }
  }

  @media (max-width: 505px) {
    .app-content {
      padding: 80px 16px 0 !important;
    }
  }

  @media (min-width: 769px) {
    .sidebar-open {
      max-width: calc(100vw - 260px) !important;
    }
    .sidebar-close {
      max-width: calc(100vw - 98px) !important;
    }
  }
</style>
