<template>
  <div id="navbar">
    <div class="side">
      <div
        class="toggle"
        @click="openFullscreenMenu"
      >
        <HamburgerIcon class="hamburger-icon" />
      </div>
      <div class="logo">
        <Logo class="logo-icon" />
      </div>
    </div>

    <SiteSelector
      :device="device"
      origin="navbar"
    />

    <div
      id="profile_trigger_navbar"
      class="profile"
      tabindex="0"
    >
      <div class="user">
        <h3>{{ user.first_name }} {{ user.last_name }}</h3>
        <h2>{{ user.job_role }}</h2>
      </div>
      <div class="picture">
        <img
          v-if="user.photo"
          :src="user.photo"
          alt="User photo"
        />
        <AvatarPlaceholder
          v-else
          size="38px"
          :text="picturePlaceholder"
        />
      </div>
      <div class="arrow-down">
        <ArrowDown class="arrow-down-icon" />
      </div>
    </div>
    <Popover
      :items="items"
      target="profile_trigger_navbar"
      :title="$t('Menu.Titles.Account')"
      placement="down"
      custom-position="navbar"
      triggers="focus"
    />
    <transition name="menumodal">
      <Menu
        :user="user"
        :device="device"
        v-if="open"
        wide
        @close="closeFullscreenMenu"
        @logout="logout"
      />
    </transition>
  </div>
</template>

<script>
  import Logo from '@core/assets/icons/logo-short.svg';
  import HamburgerIcon from '@core/assets/icons/hamburger-icon.svg';
  import ArrowDown from '@core/assets/icons/arrow-down.svg';
  import ProfileIcon from '@core/assets/icons/profile-icon.svg';
  import ExitIcon from '@core/assets/icons/exit-icon.svg';

  import Menu from '../Sidebar/index.vue';
  import Popover from '../Popover.vue';
  import SiteSelector from '../SiteSelector/index.vue';

  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';

  const ICONS = {
    ProfileIcon,
    ExitIcon
  };

  export default {
    name: 'NavbarApp',
    props: {
      user: {
        type: Object,
        default: { first_name: '', last_name: '', photo: '' }
      },
      device: {
        type: String,
        default: 'desktop'
      }
    },
    components: { Logo, HamburgerIcon, Menu, ArrowDown, Popover, SiteSelector, AvatarPlaceholder },
    data() {
      var self = this;
      return {
        open: false,

        items: [
          {
            title: 'Menu.Items.Account',
            icon: ICONS.ProfileIcon,
            click: () =>
              (window.location.href = `${this.$myIndLink}${localStorage.getItem('prefix')}/account`)
          },
          {
            title: 'Menu.Items.Exit',
            icon: ICONS.ExitIcon,
            click: () => self.logout()
          }
        ]
      };
    },
    methods: {
      logout() {
        this.$emit('logout');
      },
      openFullscreenMenu() {
        this.open = true;
      },
      closeFullscreenMenu() {
        this.open = false;
      }
    },
    computed: {
      picturePlaceholder() {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`;
      }
    }
  };
</script>

<style lang="scss">
  .menumodal-enter-active,
  .menumodal-leave-active {
    transition: all 0.5s;
    margin-left: 0px !important;
  }

  .menumodal-enter,
  .menumodal-leave-to {
    margin-left: calc(-100vw) !important;
  }
</style>

<style lang="scss" scoped>
  #navbar {
    width: 100%;
    height: 60px;
    padding: 0 29px;
    display: flex;
    flex-direction: row;
    background: #fff;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      z-index: -1;
    }
  }

  .side {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 20px;

    .toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 100%;
      svg {
        fill: #4c4541;
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 100%;
    }
  }

  .profile {
    cursor: pointer;
    border-left: 1px solid #eee;
    height: 100%;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;

    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: flex-end;
      width: 93px;

      h3,
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
      }

      h3 {
        color: #4c4541;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h2 {
        color: #998f8a;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .picture {
      height: 100%;
      display: flex;
      align-items: center;
    }

    img {
      width: 38px;
      height: 38px;
      object-fit: cover;
      border-radius: 100%;
    }

    .arrow-down {
      height: 100%;
      width: 16px;
      display: flex;
      align-items: center;
      svg {
        fill: #998f8a;
      }
    }
  }

  .hamburger-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .logo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
  }

  .arrow-down-icon {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 480px) {
    #navbar {
      padding: 0 16px !important;
    }
  }
</style>
