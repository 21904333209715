var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"sidebar",class:[_vm.isOpen ? 'open' : '', _vm.wide ? 'wide' : ''],attrs:{"id":"menu-bar"}},[_c('div',{class:['logo-container', _vm.isOpen ? 'logo-full' : 'logo']},[(_vm.isOpen)?_c('LogoFull',{staticClass:"logo-full-icon"}):_c('LogoShort',{staticClass:"logo-short-icon"}),(_vm.wide)?_c('button',{staticClass:"menu-full-close-btn",on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',[_c('CloseIcon',{staticClass:"close-icon",style:({ width: '12px !important', height: '12px !important' })})],1)]):_vm._e()],1),_c('div',{staticClass:"user-info"},[_c('Profile',{attrs:{"variant":_vm.variant,"user":_vm.user,"title":_vm.$t('Menu.Titles.Account')},on:{"close":function($event){return _vm.$emit('close')},"logout":function($event){return _vm.$emit('logout')}}})],1),_c('NavItemGroup',{ref:"generalGroup",attrs:{"title":_vm.$t('Menu.Groups.General'),"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.MyInd.Support'); })
        ),expression:"\n          isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.MyInd.Support'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.MyInd'),"variant":_vm.variant,"selected":_vm.currentPage.includes('myind'),"icon":_vm.icons.MyIndIcon,"data-redirect":"home"},on:{"click":function () { return _vm.redirect('myind'); }}}),_c('LanguageSelector',{attrs:{"variant":_vm.variant}})],1),_c('NavItemGroup',{ref:"funcionalitiesGroup",attrs:{"title":_vm.$t('Menu.Groups.Funcionalities'),"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen
            ? true
            : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Dashboard'); })
        ),expression:"\n          isOpen\n            ? true\n            : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Dashboard'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.Dashboard'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/dashboard'),"icon":_vm.icons.DashboardIcon,"data-redirect":"my-connect-dashboard"},on:{"click":function () { return _vm.redirect('my-connect-dashboard'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen
            ? true
            : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.DataSource'); })
        ),expression:"\n          isOpen\n            ? true\n            : !overflowNavItems.some((item) => item.name == $t('Menu.Items.DataSource'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.DataSource'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/data-source'),"icon":_vm.icons.DataSourceIcon,"data-redirect":"data-source"},on:{"click":function () { return _vm.redirect('data-source'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen
            ? true
            : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Pipelines'); })
        ),expression:"\n          isOpen\n            ? true\n            : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Pipelines'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.Pipelines'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/pipelines'),"icon":_vm.icons.HierarchyIcon,"data-redirect":"pipelines"},on:{"click":function () { return _vm.redirect('pipelines'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen
            ? true
            : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.LogCenter'); })
        ),expression:"\n          isOpen\n            ? true\n            : !overflowNavItems.some((item) => item.name == $t('Menu.Items.LogCenter'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.LogCenter'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/log-center'),"icon":_vm.icons.LogCenterIcon,"data-redirect":"log-center"},on:{"click":function () { return _vm.redirect('log-center'); }}}),_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Shir'); })
        ),expression:"\n          isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Shir'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.Shir'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/download-client'),"icon":_vm.icons.DownloadIcon,"data-redirect":"download-client"},on:{"click":function () { return _vm.redirect('download-client'); }}})],1),_c('NavItemGroup',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.group == _vm.$t('Menu.Groups.Help'); })
      ),expression:"\n        isOpen ? true : !overflowNavItems.some((item) => item.group == $t('Menu.Groups.Help'))\n      "}],ref:"helpGroup",staticClass:"last-side-item",attrs:{"title":_vm.$t('Menu.Groups.Help'),"border":false,"variant":_vm.variant}},[_c('NavItem',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isOpen ? true : !_vm.overflowNavItems.some(function (item) { return item.name == _vm.$t('Menu.Items.Support'); })
        ),expression:"\n          isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Menu.Items.Support'))\n        "}],attrs:{"name":_vm.$t('Menu.Items.Support'),"variant":_vm.variant,"selected":_vm.currentPage.includes('/support'),"icon":_vm.icons.HeadsetIcon,"data-redirect":"support"},on:{"click":function () { return _vm.redirect('support'); }}})],1),_c('Extra',{directives:[{name:"show",rawName:"v-show",value:(_vm.overflowNavItems.length),expression:"overflowNavItems.length"}],attrs:{"items":_vm.overflowNavItems,"variant":_vm.variant}}),_c('SiteSelector',{attrs:{"variant":_vm.variant,"device":_vm.device,"origin":"sidebar"}})],1),(!_vm.wide)?_c('button',{staticClass:"toggle-sidebar",on:{"click":_vm.toggleSidebar}},[(_vm.isOpen)?_c('span',[_c('ArrowLeft',{staticClass:"toggle-icon"})],1):_c('span',[_c('ArrowRight',{staticClass:"toggle-icon"})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }