<template>
  <div
    class="custom-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height: inherit"
  >
    <!-- Vertical Nav Menu Overlay -->
    <div class="sidenav-overlay" :class="overlayClasses" @click="isVerticalMenuActive = false" />
    <!-- /Vertical Nav Menu Overlay -->

    <Sidebar id="sidebar" :device="device" v-if="!authPages.some((item) => item === $route.name)" />

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <div class="app-content-container">
      <transition :name="routerTransition" mode="out-in">
        <component
          :is="layoutContentRenderer"
          :key="
            layoutContentRenderer === 'layout-content-renderer-left'
              ? $route.meta.navActiveLink || $route.name
              : null
          "
          :class="{ mobile: !device }"
        >
          <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </component>
      </transition>

      <footer class="footer footer-light" :class="[footerTypeClass]">
        <app-footer />
      </footer>
    </div>

    <slot name="customizer" />
  </div>
</template>

<script>
  import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
  import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue';
  import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
  import AppFooter from '@core/layouts/components/AppFooter.vue';
  import useAppConfig from '@core/app-config/useAppConfig';
  import { BNavbar } from 'bootstrap-vue';
  import { useScrollListener } from '@core/comp-functions/misc/event-listeners';
  import verticalNavMenuItems from '@/navigation/vertical';

  import { onUnmounted } from '@vue/composition-api';

  // Content Renderer
  import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue';
  import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue';
  import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue';
  import useLayoutHorizontal from './useLayoutHorizontal';
  import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue';
  import Sidebar from './components/Menu/index.vue';

  // Vertical Menu
  /* eslint-disable import/order */
  import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue';
  import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout';
  import mixinLayoutHorizontal from './mixinLayoutHorizontal';
  /* eslint-enable import/order */

  export default {
    components: {
      AppBreadcrumb,
      AppNavbarHorizontalLayout,
      AppNavbarHorizontalLayoutBrand,
      AppFooter,
      HorizontalNavMenu,

      BNavbar,

      // Content Renderer
      LayoutContentRendererDefault,
      LayoutContentRendererLeft,
      LayoutContentRendererLeftDetached,

      // Vertical Menu
      VerticalNavMenu,
      // Menu,
      Sidebar
    },
    mixins: [mixinLayoutHorizontal],
    computed: {
      layoutContentRenderer() {
        const rendererType = this.$route.meta.contentRenderer;
        if (rendererType === 'sidebar-left') return 'layout-content-renderer-left';
        if (rendererType === 'sidebar-left-detached')
          return 'layout-content-renderer-left-detached';
        return 'layout-content-renderer-default';
      }
    },
    data() {
      return {
        authPages: ['login', 'reset-password', 'invalid-link', 'not-authorized', 'forgot', 'authenticate-required'],
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 425 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 425 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      }
    },
    setup() {
      const { skin, navbarType, footerType, routerTransition, isNavMenuHidden } = useAppConfig();

      // Vertical Menu
      const { isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler } =
        useVerticalLayout(navbarType, footerType);

      // Resize handler
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      onUnmounted(() => {
        window.removeEventListener('resize', resizeHandler);
      });

      const { navbarMenuTypeClass, layoutClasses, footerTypeClass } = useLayoutHorizontal(
        navbarType,
        footerType,
        isVerticalMenuActive
      );

      // Scroll Listener
      const { scrolledTo } = useScrollListener();

      return {
        // skin
        skin,

        // Layout
        layoutClasses,

        // Navbar
        navbarType,
        navbarMenuTypeClass,

        // Menu Hidden
        isNavMenuHidden,

        // Router Transition
        routerTransition,

        // Footer
        footerTypeClass,

        // Scroll Listeners
        scrolledTo,

        // Vertical Menu
        isVerticalMenuActive,
        toggleVerticalMenuActive,
        overlayClasses,
        verticalNavMenuItems
      };
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/base/themes/bordered-layout.scss';

  .operator-mode-navbar {
    box-shadow: none !important;
    border-bottom: 1px solid #cfc4be !important;
  }

  .app-content-container {
    width: 100%;
    max-width: 100vw;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .custom-layout {
    display: flex;
    align-items: start;
    flex-direction: row;
  }

  #sidebar {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  @media (max-width: 768px) {
    .mobile {
      margin-left: 0 !important;
    }
    #sidebar {
      position: fixed;
      width: 100%;
    }
    .custom-layout {
      flex-direction: column;
      align-items: normal;
    }
  }
</style>
